(function() {
  var pagesHooks;

  $(function() {

    $(document).on("click", ".content-toggle", function(e){
      toggle = $(this).data('toggle');
      sku = $(this).data('sku');
      $(this).blur();
      if(sku == undefined && $(".content.content-" + toggle).hasClass("active")){
        // Load if needed
        $.ajax({url: "/load?partial=" + toggle + "&sku=" + sku});
      } else {
        // Toggle display
        $(".content").removeClass("active")
        $(".content.content-" + toggle).addClass("active")
        
        // Update URL
        if(sku){
          path = toggle + "/" + sku;
          // Load if sku is present
          $('.content-product-body').html("").addClass("d-none")
          $('.content-product-loader').removeClass("d-none").hide().fadeIn()
          $.ajax({url: "/load?partial=" + toggle + "&sku=" + sku});

          // Toggle nav
          $(".content-toggle").removeClass("text-white").addClass("text-info")
          $(".content-toggle[data-toggle='"+ toggle +"s']").removeClass("text-info").addClass("text-white")
        } else {
          path = toggle;

          // Toggle nav
          $(".content-toggle").removeClass("text-white").addClass("text-info")
          $(".content-toggle[data-toggle='"+ toggle +"']").removeClass("text-info").addClass("text-white")

          // Load if needed
          if(!$(".content.content-" + toggle).hasClass('loaded')){
            $.ajax({url: "/load?partial=" + toggle});
          }

          // Flash content
          $(".content.content-" + toggle).hide().fadeIn()
        }
        
        nextURL = location.protocol + "//" + location.host + "/" + path;
        nextTitle = $(this).data('title');
        nextState = { additionalInformation: 'Updated the URL via JS' };
        window.history.pushState(nextState, nextTitle, nextURL);

        $("#navbarNav").removeClass("show");
        $(".mobile-menu-link").addClass("collapsed");
        $(".mobile-menu-link").attr("aria-expanded","false");
      }

      // Scroll to top
      $("html, body").animate({ scrollTop: 0 }, "slow");

      // Collapse Nav
      $(".navbar-collapse").addClass("collapse")
      e.preventDefault()

      // Hide flash alert
      $(".flash-message .alert").fadeOut()
    })

    $(document).ready(pagesHooks);
    return $(document).on("page:load", pagesHooks);
  });

  pagesHooks = function() {
    
  
  };

}).call(this);