document.addEventListener("turbolinks:load", function() {
  customHooks();
})

$(function() {

  $(document).on("click", ".load-on-click", function(e){
    $(this).parent().addClass("show-on-load")
    $(this).parent().children().hide()
    $(this).parent().append("<img width=\"16\" height=\"16\" src=\"/loader.gif\">")
  })

  $(document).on("click", ".no-link", function(e) {
    e.preventDefault()
  });

  $(document).on("click", "[data-toggle-class]", function(e) {
    target = $(this).data("target")
    toggle_class = $(this).data("toggle-class")
    $(target).toggleClass(toggle_class)
    
  });

  $(document).on("click", "[data-add-value]", function(e) {
    e.preventDefault()
    add_value = parseInt($(this).data("add-value"))
    target = $(this).data("target")
    current_value = parseInt($(target).val())
    $(target).val(current_value + add_value)
    if ($(this).hasClass("then-submit")) {
      $(this).parents("form").first().submit()
    }
  });

  $(document).on("click", "[data-set-value]", function(e) {
    e.preventDefault()
    set_value = $(this).data("set-value")
    target = $(this).data("target")
    $(target).val(set_value)
  });

  $(document).on("change", ".zero-default", function(e) {
    if ($(this).val() == ""){
      $(this).val("0")
    }
  });

  $(document).on("change", ".autosave-field", function(e) {
    account_id = $(this).data("account-id")
    product_id = $(this).data("product-id")
    prc = $(this).val()
    qty = $(this).data("quantity")
    $.ajax({
      url: "/inventory/accounts/"+account_id+"/update_price",
      method: 'post',
      data: {
        product_id: product_id,
        price: prc,
        quantity: qty
      }
    }).done(function(data) {
      val = (qty * prc).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      $(".product-value-"+product_id).text(val.replace(".00",""))

      total = 0.0
      $(".inventory-item").each(function(){
        total += Number($(this).find(".product-value").first().text().replaceAll(",",""))
      });
      val = total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      $(".inventory-value").text(val)
    });
  });

  $(document).on("click", "[data-modal-target]", function(e) {
    e.preventDefault()
    target = $(this).data("modal-target")
    url = $(this).data("modal-url")
    $(target).find(".modal-title").html($(this).data("modal-title"))
    $(target).find(".modal-loader").removeClass("d-none")
    $(target).find(".modal-body").addClass("d-none")
    $(target).modal('show')
    $.ajax({
      url: url,
      method: 'post'
    }).done(function(data) {
      $("#previewModal .modal-body").html(data)
      $("#previewModal .modal-loader").addClass("d-none")
      $("#previewModal .modal-body").removeClass("d-none")
    });
  });


  $(document).on("keypress", "input.decimal", function(e) {
    if (!/^[0-9.]*$/.test(String.fromCharCode(e.which))) {
      return false;
    }
  });

  $(document).on("keypress", "input.alpha-numeric", function(e) {
    if (!/[0-9a-zA-Z-]/.test(String.fromCharCode(e.which))) {
      return false;
    }
  });

  $(document).on("keypress", "input.hex", function(e) {
    if (!/[0-9a-fA-F]/.test(String.fromCharCode(e.which))) {
      return false;
    }
  });

  $(document).on("keypress", "input.numeric", function(e) {
    if ($(this).data("num-negative")) {
      if (!/[0-9]|\-/.test(String.fromCharCode(e.which))) {
        return false;
      }
    } else {
      if (!/[0-9]/.test(String.fromCharCode(e.which))) {
        return false;
      }
    }
  });
});

function showOnLoad() {
  elem = $(".show-on-load")
  elem.find("img").remove()
  elem.children().show()
  elem.removeClass("show-on-load")
}


function customHooks() {
  $(".focus").first().focus();
  $(".numpad").numpad();
  // $('.table-frozen').freezeTable({shadow: true, columnNum: 0, scrollBar: true, fixedNavbar: ".navbar"});

  $('.suggest-products').autocomplete({
    maxResult: 999,
    filterMinChars: 3,
    onPick(el, item) {
      value = $("#product-list option[data-fullname=\""+item.text.trim()+"\"]").attr('value');
      selector = $('.suggest-products').data('fill-selector');
      $(selector).val(value);
      $('.suggest-products').parents('form').submit();
    }
  });

  $('.suggest-products-ajax').autocomplete({
    maxResult: 999,
    onPick(el, item) {
      value = item.text.split('#').pop();
      selector = $('.suggest-products-ajax').data('fill-selector');
      $(selector).val(value);
      $('.suggest-products-ajax').parents('form').submit();
    }
  });


  total = 0.0
  $(".inventory-item").each(function(){
    total += Number($(this).find(".product-value").first().text().replaceAll(",",""))
  });
  val = total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  $(".inventory-value").text(val)
}